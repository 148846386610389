import React, { useState } from "react"
import useLocalStorage from "../useLocalStorage"
export const StateContext = React.createContext([])

const Provider = props => {
  const [bag, setBag] = useLocalStorage("bag", [])
  const [sample, setSample] = useLocalStorage("sample", "Thomson Products")
  const [pageAlert, setPageAlert] = useState({})
  const [siteMessage, setSiteMessage] = useLocalStorage("newhandle", false)
  const [sitePopup, setSitePopup] = useState(true)
  const [cart, setCart] = useLocalStorage("cart", false)
  const [searchOpen, setSearchOpen] = useState(false)
  return (
    <StateContext.Provider
      value={{
        stateBag: [bag, setBag],
        stateSample: [sample, setSample],
        stateAlert: [pageAlert, setPageAlert],
        stateMessage: [siteMessage, setSiteMessage],
        statePopup: [sitePopup, setSitePopup],
        stateCart: [cart, setCart],
        stateSearch: [searchOpen, setSearchOpen],
      }}
    >
      {props.children}
    </StateContext.Provider>
  )
}
const ProviderExport = ({ element }) => <Provider>{element}</Provider>
export default ProviderExport
